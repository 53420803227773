import React from "react";
import "./about.css";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
export function About() {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__content">
          <p>
            Hi there! My name is Nabeel Sabzwari, and I am a master's student at
            UC Davis majoring in computer science. I am passionate about
            building innovative solutions and problem-solving. I am always eager
            to apply my skills in various opportunities, collaborate with
            diverse teams, and contribute to meaningful projects.
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}
