import React from "react";
import Resume from "../../assets/Nabeel_Sabzwari_Resume.pdf";
const CTA = () => {
  return (
    <div className="cta">
      <a href="#contact" className="btn btn-primary">
        Let's Connect!
      </a>
    </div>
  );
};

export default CTA;
